import React from 'react';

export const PdfViewingModal = ({open, pdfUrl }) => {    

    return (
        <div className='w-6/12'>
         <iframe
                    className="overflow-y-scroll scroll-touch "                    
                    src={pdfUrl}
                    width={'100%'}
                    height={'100%'}
                />    
    </div>
  );
};
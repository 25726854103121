
export const getDataSetClient = () => {
    const pathSegments = window?.location?.pathname?.split('/');
    
    if (pathSegments?.includes('sebi')) {
      return 'sebi';
    } else if (pathSegments?.includes('contract')) {
      return 'contract';
    } else if (pathSegments?.includes('legalresearch')) {
      return 'laws';
    } else {
      return 'own';
    }
  };
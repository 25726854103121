async function httpRequest(url, options) {
    const defaultHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    try {
        const response = await fetch(url, {
            headers: { ...defaultHeaders, ...options.headers },
            method: options.method,
            body: options.body ? JSON.stringify(options.body) : undefined
        });

        const responseData = await response.json();

        return responseData;
    } catch (error) {
        console.error('Error during API request:', error);
        throw error;
    }
}


export default httpRequest;
import React from 'react';
import '../AuthForm.css';
import Graphics from '../Graphics';
import WebLogo from '../../images/WebLogo.png';
import { useNavigate } from "react-router-dom";
import { isSebi } from '../../App';

const FogotPasswordResult = () => {
  const navigate = useNavigate();
  
  return (
    <div>
      <div className='auth-form-divider'>
        <div className='graphics'>
          <Graphics/>
        </div>
        <div className="auth-form-container">
          <div className='weblogoImg'>
            <img src={WebLogo} width={'180px'} height={'100%'}/>
          </div>
          <p className='message'>Reset Link Sent to registered email</p>
          <button
            className='auth-button'
            onClick={() => {
              navigate(isSebi?"/sebi/login":"/");
            }}
          >
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default FogotPasswordResult;
import React, {useEffect, useState} from "react";
import { getTextonStepTwo, updateContractStatus } from "../../apis";
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function TemplateDraft(){

    const [htmlContent, setHtmlContent] = useState('');
    let { id, conversationId } = useParams();
    const navigate = useNavigate();
    const tokenUpdated = localStorage.getItem('chattoken');

    useEffect(() => {
        const fetchTextOnStepTwo = async () => {
            try {
                const data = await getTextonStepTwo(id, tokenUpdated);
                setHtmlContent(data?.data);
            } catch (error) {
                console.error('Error fetching draft types:', error);
            }
        };
        localStorage.setItem('chatboxId', conversationId)
        fetchTextOnStepTwo();
    },[id])

    const handleUpdateContractStatus = async () => {
        try {
            const data = await updateContractStatus(id, {status: "Step 2 Complete"}, tokenUpdated);
            toast('Template updated successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                color: 'red'
            });
            navigate(`/contract/chat/${data?.id}/${conversationId}`)
        } catch (error) {
            console.error('Error fetching draft types:', error);
        }
    }

    return(
        <>
           <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
           <Button variant='contained' color='primary' onClick={handleUpdateContractStatus} > Use This Template</Button>
           <ToastContainer/>
        </>
    )
}
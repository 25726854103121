import httpRequest from './httpRequest';
import { API_URL } from '../config/config';

const tokenUpdated = localStorage.getItem('chattoken');
const chatBoxId = localStorage.getItem('chatboxId');

const customHeader = { 'Content-Type': 'application/x-www-form-urlencoded', 'authorization': "Bearer " + tokenUpdated };

async function loginSubmission(data) {
    const options = {
        method: 'POST',
        body: data
    };
    return await httpRequest(`${API_URL}/users/login/`, options);
}

async function registerSubmission(data) {
    const options = {
        method: 'POST',
        body: data
    };
    return await httpRequest(`${API_URL}/users/register/`, options);
}

async function sendOTP(data) {
    const options = {
        method: 'POST',
        body: data
    };
    return await httpRequest(`${API_URL}/users/get-otp-email/`, options);
}

async function getDraftTypes(token){
    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}` // Add the authorization header with the token
        }
    };
    return await httpRequest(`${API_URL}/contract/contracttype/template/`, options);
}

async function getFieldsByType(fields_by_type, token){
    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}` // Add the authorization header with the token
        }
    };
    return await httpRequest(`${API_URL}/contract/fields/fields_by_type/?code=${fields_by_type}`, options);
}

async function createContract(data, token){
    const options = {
        method: 'POST',
        body: data,
        headers: {
            'Authorization': `Bearer ${token}` // Add the authorization header with the token
        }
    };
    return await httpRequest(`${API_URL}/contract/contract/`, options);
}

async function getContractsForUser(token){
    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}` // Add the authorization header with the token
        }
    };
    return await httpRequest(`${API_URL}/contract/contract/`, options);
}

async function getTextonStepTwo(contractId, token){
    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    };
    return await httpRequest(`${API_URL}/contract/contract/${contractId}/abouttext/`, options);
}

async function updateContractStatus(contractId, data, token){
    const options = {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: data
    };
    return await httpRequest(`${API_URL}/contract/contract/${contractId}/`, options);
}

async function getFileContentsOfContract(contractId, token){
    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    };
    return await httpRequest(`${API_URL}/contract/contract/${contractId}/draft/`, options);
}

async function createConversationDraft(data, token){
    const options = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: data
    };
    return await httpRequest(`${API_URL}/contract/conversation/`, options);
}

async function communicateDraft(chatBoxId, data, token){
    const options = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: data
    };
    return await httpRequest(`${API_URL}/contract/conversation/${chatBoxId}/communicate/`, options);
}

async function publishDraft(contractId, data, token){
    //   {file: 'doczx'}
    const options = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: data
    };
    return await httpRequest(`${API_URL}/contract/contract/${contractId}/publish/`, options)
}

async function getDraftConversationForId(conversationId, token){
    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    };
    return await httpRequest(`${API_URL}/contract/conversation/${conversationId}/`, options)
}

async function downloadJugdementDraft(data, messageId, token) {
    const options = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        },
        body: data
    };
    return await httpRequest(`${API_URL}/chatbot/messages/${messageId}/download_judgement/`, options);
}


export {
    loginSubmission,
    registerSubmission,
    sendOTP,
    getDraftTypes,
    getFieldsByType,
    createContract,
    getContractsForUser,
    getTextonStepTwo,
    updateContractStatus,
    getFileContentsOfContract,
    createConversationDraft,
    communicateDraft,
    publishDraft,
    getDraftConversationForId,
    downloadJugdementDraft
}
import { useState, useEffect, useRef } from 'react';
import { Tooltip, DialogTitle, Dialog, DialogContent, DialogActions, Button, Typography, Rating, TextField, InputAdornment, Checkbox, FormControlLabel, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import './Chat.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import { useNavigate } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../config/config';
import {ThreeDots} from 'react-loader-spinner';
import ReviewFormDialog from './ReviewFormDialog';
import { isLaws, isSebi } from '../App';
import Arrow from '../images/Arrow.svg';
import Webnyay_favicon from '../images/Webnyay_favicon.svg';
import { ExpandMore, Person, SaveAlt, SmsFailedOutlined } from '@mui/icons-material';
import { getDataSetClient } from '../Utils/getDataSetClient';
import Laws from './Laws';
import { GENERATE_JUDGEMENT } from '../constants';
import { downloadJugdementDraft } from '../apis';

const JudgementDraftResponse = ({response, processText, messageId}) => {
  const [sections, setSections] = useState([]);
  const tokenUpdated = localStorage.getItem('chattoken');
  useEffect(()=>{
    const parsedSections = JSON.parse(response)?.map(section => {
      return {
        ...section,
        checked: false,
        content: section?.content?.map((subSection) => {
          return {...subSection, checked: false}
        })
      }
    })
    setSections(parsedSections); 
  },[response])

  const parseHtmlText = (text) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    const targetDiv = doc.getElementsByTagName(`body`);
    const elements = [...targetDiv[0].children];
    
    return elements.map((el) => ({
      heading: el.localName,
      content: el.textContent?.trim(),
      checked: false,
    }));
  };

  const handleCheckboxChange = (index) => {
    const updatedChecked = sections.map((section, idx) =>
      idx === index ? (
        {
          ...section, 
          checked: !section?.checked,
          content: section?.content?.map((subSection) => {
            return {...subSection, checked: !section?.checked}
          })
        }
      ) : (
        section
      )
    );
    setSections(updatedChecked);
  };

  const handleClickDownload = async () => {
    try {
      const parts = sections.reduce((obj, section) => {
        const checkedSubSections = section?.content.filter(subSection => subSection.checked);
        // Add the header only if there is at least one checked sub_section
        if(checkedSubSections.length > 0) {
          obj[section.header] = section?.checked || (checkedSubSections?.length === section?.content?.length)
            ? []
            : checkedSubSections.map(subSection => subSection?.sub_header);
        }
        return obj;
      }, {});
      const data = {
        parts: parts,
      }
      const response = await downloadJugdementDraft(data,messageId,tokenUpdated);
      console.log('res',response);
      if(response?.judgement_url){
        window.location = response?.judgement_url;
        toast('Judgement Draft downloaded successfully', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          color: 'red'
        });
      }
    } catch (error) {
        console.error('Error downloading Judgement Draft', error);
    }
  };

  const handleSelectAll = () => {
    const updatedChecked = sections.map((section) => {
      section.checked = true;
      section.content = section?.content.map(subSection => ({...subSection, checked: true}));
      return section;
    }
      
    );
    setSections(updatedChecked);
  }

  const subHeadingToShow = (tag) => {
    console.log('tag',tag);
    switch(tag){
      case 'metadata':
        return 'Case Details';
      case 'introduction':
        return 'Introduction to the Case';
      case 'facts':
        return 'Facts of the Case';
      case 'issues':
        return 'Issues of the law';
      case 'submission_petitioner':
        return `Petitioners' Submissions`;
      case 'submission_respondent':
        return `Respondents' Submissions`;
      case 'citations':
        return `Laws and Cases Referred`;
      default:
        return tag;
    }
  }

  const handleCheckboxChangeSubHeader = (headerIndex, subHeaderIndex) => {
    sections[headerIndex].content[subHeaderIndex].checked = !sections[headerIndex]?.content[subHeaderIndex]?.checked;
    const isUncheckedSubSection = sections[headerIndex]?.content?.find(subSection => subSection?.checked === false);
    sections[headerIndex].checked = isUncheckedSubSection ? false : true;
    setSections([...sections]);
  }

  return (
    <div>
      {sections.map((section, index) => (
        <div
          key={index}
          className={`section-container ${section.checked ? 'highlighted' : ''}`}
        >
          <Accordion defaultExpanded sx={{backgroundColor:'transparent'}}>
            <AccordionSummary expandIcon={<ExpandMore/>} sx={{'& .MuiAccordionSummary-content':{m:0}}} style={{minHeight:'auto'}}>
              <FormControlLabel 
                control={<Checkbox/>} 
                checked={section?.checked} 
                // label={subHeadingToShow(section?.heading)} 
                label={section?.header}
                onChange={(e) => {
                  handleCheckboxChange(index)
                  // e.stopPropagation();
                }}
                onClick={(e) => e.stopPropagation()} // Prevent accordion toggle
                style={{width:'auto'}}
              />
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {/* {processText(section.content)} */}
                {section?.content.map((subSection, idx)=>{
                  return (
                    <div
                      key={idx}
                      className={`section-container ${subSection?.checked ? 'highlighted' : ''}`}
                    >
                      <Accordion defaultExpanded={subSection?.sub_header !== 'Relevant Acts/Rules Referred'} sx={{backgroundColor:'transparent'}}>
                        <AccordionSummary expandIcon={<ExpandMore/>}  sx={{pl:'24px', '& .MuiAccordionSummary-content':{m:0}}} style={{minHeight:'auto'}}>
                          <FormControlLabel 
                            control={<Checkbox/>} 
                            checked={subSection?.checked} 
                            label={subSection?.sub_header}
                            onChange={(e) => {
                              handleCheckboxChangeSubHeader(index,idx)
                            }}
                            onClick={(e) => e.stopPropagation()}
                            style={{width:'auto'}}
                          />
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{width:'70%',marginLeft:'auto'}}>
                            {subSection?.sub_header === 'Relevant Acts/Rules Referred' ? (
                              subSection?.sub_content?.map((content,index) => {
                                return (
                                  <div key={index} dangerouslySetInnerHTML={{__html: content}}></div>
                                )
                              })
                            ) : (
                              processText(subSection?.sub_content)
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )
                })}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
      <div style={{display:'flex',marginLeft:'auto',width:'fit-content',gap:'1rem'}}>
        <Button
          color='primary'
          onClick={handleSelectAll}
          style={{textTransform:'capitalize', textDecoration:'underline',fontFamily:'Satoshi-Bold'}}
        >
          Select All
        </Button>
        <Button
          variant='contained'
          color='primary'
          startIcon={<SaveAlt/>}
          style={{borderRadius:'8px',textTransform:'capitalize',display:'flex',marginLeft:'auto'}}
          onClick={handleClickDownload}
          disabled={sections.every(section => 
            !section.checked && section.content.every(subSection => !subSection.checked)
          )}
        >
          Download Judgement
        </Button>
      </div>
    </div>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Chat({onCommandSubmit, allChatData, spinner, user, filesUploaded, setPdfUrlForViewer, pdfUrlForViewer, setSourcePageNumber,  setShowPdfModal}) {
    const [command, setCommand] = useState('');
    const [text, setText] = useState('');
    const [source, setSource] = useState([])
    const [rateReviewModal, setRateReviewModal] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [selectedLaw, setSelectedLaw] = useState('');
    const anchorRef = useRef(null);

    const fullName = user?.name?.trim();
    var first_name = fullName?.substring(0, fullName?.lastIndexOf(' '));
    var last_name = fullName?.substring(fullName?.lastIndexOf(' ') + 1, fullName?.length);

    const initialRatings = {
      accuracy_rating: 0,
      helpful_rating: 0,
      source_rating: 0,
      length_rating: 0,
    }

    const [ratings, setRatings] = useState(initialRatings);
  
    const [comment, setComment] = useState('');
    const [messageIdForReview, setMessageIdForReview] = useState('')

    const [isCopiedStates, setIsCopiedStates] = useState([]); // An array to store isCopied state for each chat item

    const handleCopyClick = (result, index) => {
      navigator.clipboard.writeText(result);
  
      // Update the isCopied state for the specific chat item
      const newIsCopiedStates = [...isCopiedStates];
      newIsCopiedStates[index] = true;
      setIsCopiedStates(newIsCopiedStates);
  
      setTimeout(() => {
        // Reset the isCopied state for the specific chat item after 2 seconds
        const newIsCopiedStates = [...isCopiedStates];
        newIsCopiedStates[index] = false;
        setIsCopiedStates(newIsCopiedStates);
      }, 2000);
    };

    const messagesEndRef= useRef(null);
    const tokenUpdated = localStorage.getItem('chattoken');
    const navigate = useNavigate();

    const scrollToBottom = () => {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  
    useEffect(() => {
      scrollToBottom();
    }, [command, allChatData]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if(command.trim() === ''){
        return;
      }
      if((!isSebi && (filesUploaded.length === 0 ) && getDataSetClient() !== 'contract' && !isLaws)){
        alert('Please upload a document before start querying!');
        return;
      }
      if(isLaws && selectedLaw === ''){
        alert('Please select a region before start querying!');
        return;
      }
      setText(command);
      setCommand("");
      await onCommandSubmit(selectedLaw + command);
    };

    const handleEnterInput = (e) => {
      setCommand(e.target.value);
      // setText(e.target.value);
    }

    const getFile = (name) => {
      return /[^/]*$/.exec(name)[0]
    }

    const handleSourceCitationsUrl = (url) => {
      setPdfUrlForViewer(url);
      setShowPdfModal(true)
    };

    const handleSourceCitations = (pageContent) => {
      // Open a new tab with the page content
      const newTab = window.open('', '_blank');
      
      // Write the content to the new tab
      newTab.document.write(`<html><body><div>${pageContent}</div></body></html>`);
      
      // Close the document to finish writing
      newTab.document.close();
    };

    function handleRateReviewModal(messageId){
      setMessageIdForReview(messageId)
      setRateReviewModal(!rateReviewModal);
    }

    const handleRatingChange = (newValue, type) => {
      // Update the state for the corresponding type of rating
      setRatings((prevRatings) => ({
        ...prevRatings,
        [type]: newValue || 0,
      }));
    };
    const handleCommentChange = (event) => {
      setComment(event.target.value);
    };

    const isAnyRatingFilled = (ratings) => {
      return Object.values(ratings).some((value) => value > 0);
    };

    const handleSubmitReview = (event, messageIdForReview) => {
      const CHAT_FEEDBACK = {
        ...ratings,
        message: messageIdForReview,
        comment: comment 
      }
      if(!isAnyRatingFilled(ratings) && comment.length == 0){
        alert('Please review and then submit')
        return;
      }
      fetch(`${API_URL}/chatbot/chatfeedback/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenUpdated}`,
        },
        body: JSON.stringify(CHAT_FEEDBACK),
        Cache: 'default'
      }).then(function(response) {
        return response.json();
        }).then((data) => {
          toast('Answer Reviewed Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            color: 'red'
          });
          setRateReviewModal(false);
          setComment('')
          setRatings(initialRatings)
      })
      .catch((err)=>{
        console.log(err);
      })
    }

      const processText = (text) => {
        const lines = text.split('\n');
    
        return lines.map((line, index) => {
            const parts = line.split(/(\*\*[^*]+\*\*)/g);
            return (
                <div key={index}>
                    {parts.map((part, partIndex) => {
                        if (part.startsWith('**') && part.endsWith('**')) {
                            return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
                        } else if (part.startsWith('- ')) {
                            return (
                                <span key={partIndex}>
                                    {part.slice(2)}
                                </span>
                            );
                        } else {
                            return <><span key={partIndex}>{part}</span><br/></>;
                        }
                    })}
                </div>
            );
        });
    };  

    const extractNumber = (input) => {
      const regex = /\((\d+)\)/;
      const match = input.match(regex);
      if (match) {
        return match[1];
      }
      return null;
    };

    const lawsList = [
      {name: 'Central Laws'},
      {name: 'Chandigarh laws'},
      {name: 'Punjab State Laws'},
      {name: 'Haryana State Laws'},
    ];

    const handleClose = (event, isLawsCategorySelected) => {
      if ((anchorRef.current && anchorRef.current.contains(event.target)) || (selectedLaw === '' && !isLawsCategorySelected) ) {
        return;
      }
      anchorRef.current.focus();
      setOpenList(false);
    };

    const handleSelectLaws = (event,item) => {
      handleClose(event, true);
      setSelectedLaw(item?.name + ': ');
    }

    const handleFocus = () => {
      if(isLaws && selectedLaw === '' && anchorRef.current ){
        setOpenList(true);
      }
    }
     
    return (
      <div className={`flex flex-col ${pdfUrlForViewer === '' ? 'w-full' : 'w-6/12'}`}>
        <div style={{height: '3rem', borderBottom: '1px solid #e5e5e5'}}></div>
        <div className='sebiChatWrapIndividual'>
          {allChatData?.map((item, index) => {
            const isGenerateJudgement = item?.content === GENERATE_JUDGEMENT;
            return(
              <div key={index}>
                <div className='messageContainer' style={isGenerateJudgement?{margin:'20px'}:null}>
                  <div style={{width:'48px'}}></div>
                  <div className='query' style={!isSebi ? {background: '#4A3AFF', width: isGenerateJudgement?'90%':''} : null}>
                    {item?.content}
                  </div>
                  <div className='avtar' style={isSebi ? {background: '#040415'} : {background: '#4A3AFF'}}>
                    <Person style={{color:'white'}}/>
                  </div>
                </div>
                <div className='messageContainer' style={isGenerateJudgement?{margin:'20px'}:null}>
                  <div className='avtar'>
                    <img src={Webnyay_favicon} alt='webnyay' width={'30px'}/>
                  </div>
                  <div className='response' style={isGenerateJudgement?{width:'90%',fontFamily:'Satoshi-Medium'}:null}>
                    {item?.content === GENERATE_JUDGEMENT ? <JudgementDraftResponse response={item?.response} processText={processText} messageId={item?.id}/> : processText(item?.result)}
                    {getDataSetClient() !== 'contract' && (item?.content !== GENERATE_JUDGEMENT) && 
                      <div className='gptSource'>
                        Sources - {
                          item?.source_citation_urls?.length > 0 ? (
                            item?.source_citation_urls?.map((source, index) => 
                              <Tooltip onClick={() => {handleSourceCitationsUrl(source.url);setSourcePageNumber(extractNumber(source?.file))}} placement="top" title={'Click to open'}>{'['+source.file+'],'}</Tooltip>
                            )
                          ) : (
                            item?.source_data?.map((sourceNumber, index) => 
                              <Tooltip onClick={() => handleSourceCitations(sourceNumber.page_content)} placement="top" title={'Click to open in new tab'}>{'['+sourceNumber.id+' - '+getFile(sourceNumber.source_file)+']'}</Tooltip>
                            )
                          )
                        }
                      </div>
                    }
                  </div>
                  <div style={{width:'48px'}}>
                    <div className='options'>
                      <div onClick={() => handleCopyClick(item.result, index)}>
                        <ContentCopyIcon sx={{fontSize: '1rem', cursor:'pointer'}} />
                      </div>
                      {isCopiedStates[index] && <div className="copiedMessage" >Copied!</div>}
                      <div onClick={() => handleRateReviewModal(item?.id)}><SmsFailedOutlined sx={{fontSize: '1rem', cursor:'pointer'}}/></div>
                    </div>
                  </div>
                </div>
              </div> 
            )
          })}
           <div ref={messagesEndRef} />
          {text && spinner ?
            <div className='messageContainer'>
              <div style={{width:'48px'}}></div>
              <div className='query' style={!isSebi?{background:'#4A3AFF'}:null}>
                {text}
              </div>
              <div className='avtar' style={isSebi?{background: '#040415'}:{background:'#4A3AFF'}}>
                <Person style={{color:'white'}}/>
              </div>
            </div>
            : null
          }
          {spinner ? 
            <div className='loaderWrapperNew'>
              <ThreeDots 
                height="80" 
                width="80" 
                radius="9"
                color="#4A3AFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
           : null
        }
        </div>
        <div className="chatwrapper">
          {isLaws && (
            <Laws
              list={lawsList}
              open={openList}
              anchorRef={anchorRef}
              handleClose={handleClose}
              handleSelect={handleSelectLaws}
            />
          )}
            <form onSubmit={handleSubmit} className="chatwrapper">
              <TextField
                className='chatInputBox'
                type="text"
                placeholder="Ask Webnyay..."
                value={command}
                onChange={(e) => handleEnterInput(e)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset, &:hover fieldset": {
                      borderColor: "#c5c5c5",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><img src={Arrow} alt="arrow" width={'45px'} onClick={handleSubmit} style={{cursor:'pointer'}}/></InputAdornment>,
                  style: {
                    borderRadius: "22px",
                    paddingRight: '8px',
                    fontSize: '14px',
                  },
                  startAdornment: <InputAdornment position='start' style={{cursor:'pointer'}} onClick={()=>setOpenList(true)}>{isLaws?selectedLaw:''}</InputAdornment>
                }}
                inputRef={anchorRef}
                onFocus={handleFocus}
                autoComplete='off'
              />
            </form>
        </div>
        <ToastContainer/>
        {rateReviewModal ?
          <ReviewFormDialog
            open={rateReviewModal}
            handleClose={handleRateReviewModal}
            handleRatingChange={handleRatingChange}
            handleCommentChange={handleCommentChange}
            handleSubmitReview={handleSubmitReview}
            ratings={ratings}
            comment={comment}
            messageIdForReview={messageIdForReview}
          />
         : null
        }
      </div>
    );
  }
  
  export default Chat;
  
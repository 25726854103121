import React, {useState} from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ConfirmationDialog from '../Shared/ConfirmationDialog';
import { CircularProgress, Tooltip } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

const FilesUploadedList = ({ filesUploaded, handleDeleteFile, handleDeleteFolder, uploadingDocumentIds, level = 0 }) => {
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const confirmDeleteFolder = (folderId) => {
    setFolderToDelete(folderId);
    setOpenConfirmation(true);
  };

  const cancelDelete = () => {
    setFolderToDelete(null);
    setOpenConfirmation(false);
  };

  const deleteFolder = () => {
    handleDeleteFolder(folderToDelete);
    setFolderToDelete(null);
    setOpenConfirmation(false);
  };
  const renderDocuments = (documents) => (
    <div>
      {documents.map((document) => {
        const isUploadingDocument = uploadingDocumentIds.includes(document.id);
        const isUnembeddedFile = document.embedding_status === 0;
        return (
          <div key={document.id} className='chatBox flex justify-between ml-3.5' style={(isUploadingDocument && isUnembeddedFile)?{ color: 'darkgray', display:'flex', justifyContent: 'space-between' }:null}>
            <div style={{wordBreak:'break-word'}}>
              <Tooltip title={document?.name}>
                <p className='line-clamp-1 text-left m-0' >
                  {document?.name}
                </p>
              </Tooltip>
            </div>
            <div>
              {isUploadingDocument ? (
                isUnembeddedFile ? (
                  <span>
                    <CircularProgress size={13}/>
                  </span>
                ) : (
                  <span>
                    <CheckCircle fontSize='small' style={{color: 'green'}}/>
                  </span>
                )
              ) : (
                <span className='deleteIcon' onClick={() => handleDeleteFile(document.id)}>
                  <DeleteOutlinedIcon fontSize='small'/>
                </span>
              )}
            </div>
          </div>
        )
      })}
    </div>
  );

  const renderFolder = (folder) => (
    <div key={folder.id}>
      {/* Display Folder Name */}
      
      <div className='chatBox flex justify-between w-full'>
        <div style={{wordBreak:'break-word'}}>{folder?.name} <span style={{color: '#989898'}}>{'('+folder?.documents?.length+')'}</span></div>
        <span className='deleteIcon' onClick={() => confirmDeleteFolder(folder.id)}>
          <DeleteOutlinedIcon fontSize='small'/>
        </span>
      </div>

      {/* Display Document Names Inside Folder */}
      {folder.documents && folder.documents.length > 0
        ? renderDocuments(folder.documents, level + 1)
        : (
          <div className='chatBox' style={{ marginLeft: `14px`, fontStyle: 'italic', color: '#999' }}>
            No documents in this folder
          </div>
        )
      }
    </div>
  );

  return (
    <div className='sebiHistoryWrapper'>
      {filesUploaded.length > 0 ? filesUploaded.map((item) => (
        <div key={item.id}>
          {/* Render Folder or Document based on type */}
          {item.documents ? renderFolder(item, level) : (
            <div className='chatBox' style={{ fontStyle: 'italic', color: '#999' }}>
              <div style={{wordBreak:'break-word'}}>{item.name}</div>
              {/* No delete button for documents */}
            </div>
          )}
        </div>
      )) : null}
      {folderToDelete !== null && (
        <ConfirmationDialog
          open={openConfirmation}
          onClose={cancelDelete}
          onConfirm={deleteFolder}
          message="Are you sure you want to delete this folder?"
        />
      )}
    </div>
  );
};

export default FilesUploadedList;

import { Box, Typography } from "@mui/material";
import React from "react";
import '../AuthForm.css';
import DocChat_White from '../../images/DocChat_White.svg';
import { FiberManualRecord } from "@mui/icons-material";


export default function SebiGraphics(){
	const points = ['Securities or public markets legal research is now a simple Q&A with the Webnyay DocChat for Securities Market app.',
					'Webnyay has processed (using AI) over 70,000 documents - the entire SEBI Legal and Enforcement Database. This is to ensure that large language models can give you instant answers to your questions.',
					`Currently <span style='font-family: Satoshi-Bold'>FREE</span> so go ahead - create an account and leverage the power of AI for legal research.`]

	return (
		<Box className='sebi-graphic-container'>
			<div>
				<img src={DocChat_White} alt="DocChat"/>
			</div>
			<div style={{height:'1.4rem'}}/>
			<p className="sebi-graphic-heading">
				DocChat for Securities Market
			</p>
			{/* <div style={{height:'1.5rem'}}/> */}
			<p>
				{points.map((item,index)=>{
					return (
						<div className="list-item" key={index}>
							<span><FiberManualRecord sx={{fontSize:"0.8rem"}} /></span>
							<span dangerouslySetInnerHTML={{__html: item}}></span>
						</div>
					)
				})}
			</p>
		</Box>
	)
}
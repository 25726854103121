import { createTheme } from "@mui/material";
import Satoshittf from './assets/fonts/Satoshi/Satoshi-Medium.ttf';

const satoshiMedium = {
  fontFamily: "Satoshi",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Satoshi'),
    local('Satoshi-Medium'),
    url(${Satoshittf}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme  = createTheme({
	palette: {
    primary: {
      main: "#4A3BFF",
      mainGradient: "#60A9F6",
    },
    secondary: {
      main: "#2BB879",
    },
    secondPrimary: "linear-gradient(325.78deg, #2A8BF2 14.76%, #7CB8F7 87.3%)",

    common: {
      black: "#000",
      white: "#fff",
      grey: "#FAFBFF",
      grey1: "#707C97",
      yellow: "#FBD56F",
      red: "#FA8282",
      green: "#33B669",
      lightBlue: "#D3E5EB",
    },
    border: "#E5E5E5",
  },
	typography: {
		// fontSize: '1rem',
		fontFamily: 'Satoshi-Regular',
	},
	shape: {
		borderRadius: 0,
	},
	shadows: Array(25).fill('none'),
	MuiCssBaseline: {
		"@global": {
			"@font-face": [satoshiMedium],
			body: {
				backgroundColor: "#fff",
				fontFamily: "Satoshi-Medium",
			},
			html: {
				scrollBehavior: "smooth",
			},
		},
	},
})

export default theme;
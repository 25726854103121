import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../components/AuthForm.css';
import { TextField, InputAdornment } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { loginSubmission } from '../apis/index';
import MessageBox from '../Shared/MessageBox/MessageBox';
import EnvelopeSimple from '../images/EnvelopeSimple.svg';
import Lock from '../images/Lock.svg';
import { isSebi } from '../App';
import SebiGraphics from '../components/SebiGraphic/SebiGraphic';
import Graphics from '../components/Graphics';
import Webnyay_favicon from '../images/Webnyay_favicon.svg';
import DocChat_Black from '../images/DocChat_Black.svg';
import { getDataSetClient } from '../Utils/getDataSetClient';

const GeneralLogin = ({ clientType }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const navigate = useNavigate();

  useEffect(()=> {
    localStorage.clear();
  },[])

  const handleRegisterClick = () => {
    navigate('/contract/register');
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      "username_or_email": email,
      "password": password,
    };
    localStorage.setItem('chatUsername', email);

    try {
      const responseData = await loginSubmission(data);

      if (responseData?.token) {
        // if ((isSebi && responseData?.dataset?.includes('sebi')) || (!isSebi && responseData?.dataset?.includes('own'))) {
          localStorage.setItem('chattoken', responseData.token.access);
          navigate('/contract/dashboard');
        // } 
        // else {
        //   setError('Unauthorized user!');
        // }
      } else if (responseData?.detail === 'Invalid credentials.') {
        setError(responseData?.detail);
      }

      return responseData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const tooglePasswordType = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const errorStyles = {
    backgroundColor: 'rgb(253, 236, 234)',
    color: 'red',
    padding: '10px 15px',
    borderRadius: '4px',
    marginTop: '10px',
  };

  return (
    <div className='auth-form-divider'>
      <div className={clientType === 'sebi' ? 'sebi-graphic' : 'graphics'}>
        {isSebi ? <SebiGraphics/> : <Graphics/>}
      </div>
      <div className="auth-form-container">
        <div className='main-container'>
        <div className='weblogoImg'>
            {getDataSetClient ==='sebi' ? (
              <img className='logo-circle' src={Webnyay_favicon} alt='webnyay'/>
            ) : (
              <>
                <img className='logo-circle' src={Webnyay_favicon} alt='webnyay'/>
                <div style={{height:'0.7rem'}}></div>
                <img src={DocChat_Black} width={'220px'} height={'100%'}/>
              </>
            )}
          </div>
          <div className='login-title'>Log in</div>
          <div className='login-subtitle'>Enter your account details below</div>
          <div style={{ height: '52px' }}></div>
          <form className="auth-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email" className='title-input'>Email address</label>
              <TextField
                variant='outlined'
                type='email'
                id='email'
                placeholder='Eg - myemail@mail.com'
                value={email}
                onChange={handleEmailChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><img src={EnvelopeSimple} alt="Envelope" /></InputAdornment>
                }}
              />
            </div>
            <div style={{ height: '10px' }} />
            <div className="form-group">
              <label htmlFor="password" className='title-input'>Password</label>
              <TextField
                id='password'
                type={passwordType}
                placeholder='Enter password'
                value={password}
                onChange={handlePasswordChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><img src={Lock} alt="Lock" /></InputAdornment>,
                  endAdornment: <InputAdornment position='end' onClick={tooglePasswordType} style={{ cursor: "pointer", color: '#5a5a5a' }}>
                    {passwordType === "password" ? (
                      <VisibilityOffOutlined fontSize='small' />
                    ) : (
                      <VisibilityOutlined fontSize='small' />
                    )}
                  </InputAdornment>
                }}
              />
            </div>
            <div style={{ display: 'grid', justifyContent: "end" }}>
              <div>
                <Link to="/forgot-password" style={{ textDecoration: 'none' }}>
                  <p className='forgot-button'>Forgot Password?</p>
                </Link>
              </div>
            </div>
            <button type="submit" className="auth-button" style={{ borderRadius: 0, fontSize: '1rem' }}>Login</button>
            {error && (
              <MessageBox
                message={error}
                styles={errorStyles}
              />
            )}
          </form>
          <div className='auth-change'>
            Don't have an account? <span className="text-blue-600 font-medium underline hover:text-blue-700 cursor-pointer" onClick={handleRegisterClick}>Register</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralLogin;

import React from "react";
import { Box, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useEffect, useRef, useState } from "react";
import './CustomCarousel.css';

export default function CustomCarousel({GraphicsCollection,style,className,delay}){
  const carouselRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, 
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (carouselRef.current) {
      observer.observe(carouselRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);


  useEffect(()=>{
    let timer;
    if (isVisible) {
      timer = setTimeout(
        () => {
          setActiveSlide((prevSlide) =>
            prevSlide === GraphicsCollection.length - 1 ? 0 : prevSlide + 1
          )
        },
        delay || 6000
      );
    }

    return () => {
      clearTimeout(timer);
    };
  },[isVisible, activeSlide])

  const CarouselDots = (number_of_dots) => {
    const arr = [];
    for(let i=0 ; i<number_of_dots ; i++){
      const activeDot = activeSlide === i;
      arr.push(<Box onClick={()=>{setActiveSlide(i)}} key={i}><FiberManualRecordIcon className={activeDot ? 'activeDot' : 'dot'}/></Box>);
    }
    return arr;
  }

  return (
    <Box id='graphicContainer' ref={carouselRef} style={{padding:'3rem'}}>
      <Box>
        <Typography className="title-graphics">
          {GraphicsCollection[activeSlide].title}
        </Typography>
        <Typography className='content-graphics' dangerouslySetInnerHTML={{ __html: GraphicsCollection[activeSlide].content }}>
        </Typography>
      </Box>
      <Box style={{height:'2rem'}}></Box>
      <Box style={{width:'25rem',margin:'auto'}}>
        <img src={GraphicsCollection[activeSlide].image_src} alt={activeSlide} width='100%'></img>
      </Box>
      <Box style={{height:'2rem'}}></Box>
      <Box className='CarouselDots_Container'>
        {CarouselDots(GraphicsCollection.length)}
      </Box>
    </Box>
  )
}
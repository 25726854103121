import React from 'react';

const MessageBox = ({
  message,
  type,
  styles,
}) => {

  return (
    <div>
      <div style={styles ? styles : {padding:'10px 15px'}}>
        {message}
      </div>
    </div>
  );
};

export default MessageBox;
import { useState, useEffect } from 'react';
import './LeftSide.css';
import { Modal, Box, Typography, Grid, Button, CircularProgress, TextField, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { API_URL } from '../config/config';
import FileUpload from './UploadFolder';
import "react-toastify/dist/ReactToastify.css";
import FilesUploadedList from './FilesUploadedList';
import { Edit } from '@mui/icons-material';
import DocChat_Black from '../images/DocChat_Black.svg';
import { isLaws, isSebi } from '../App';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function LeftSide({handleCreateNewChat, setLoader, handleOldHistoryChat, chatHistory, selectedChat, user, getChatHistory, filesUploaded, setFilesUploaded}) {
    // const isSebiUser = user?.user_type === 'sebi';

    const [activeTab, setActiveTab ]=useState('documents');
    // const [filesUploaded, setFilesUploaded] = useState([])
    
    const [uploadModalOpen, setUploadModalOpen] = useState(false)
    const [loadingFolder, setLoadingFolder] = useState(false);
    const [rename, setRename] = useState(false);
    const [renameChatid, setRenameChatid] = useState(null);

    const tokenUpdated = localStorage.getItem('chattoken');
    const chatUsername = localStorage.getItem('chatUsername')
    const navigate = useNavigate();

    const [isSebiUser, setIsSebiUser] = useState(isSebi);
    const [uploadingDocumentIds, setUploadingDocumentIds] = useState([]);

    let socket = null;

    function handleTab1(){
      setActiveTab('documents');
    }
    function handleTab2(){
      setActiveTab('chat');
    }

    const fetchFilesUploaded = () => {
      fetch(`${API_URL}/docs/folder/list_folder/`, {
        method: 'GET',
        headers: {
          'accept': 'application/vnd.oai.openapi+json',
          'Authorization': `Bearer ${tokenUpdated}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) { 
          setFilesUploaded(data);
          data?.forEach((folder)=>{
            if(folder.documents){
              let UnembeddedFiles = folder.documents.filter(document => {
                if(document.embedding_status === 0){
                  uploadingDocumentIds.push(document.id);
                  return true;
                }
                else{
                  return false;
                }
              });
              if(UnembeddedFiles?.length > 0){
                connect(folder.id, data, true);
              }
            }
          })
          setUploadingDocumentIds([...uploadingDocumentIds]);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    function fetchAndSetFilesUploaded() {
      fetch(`${API_URL}/docs/document/list_documents/`, {
        method: 'GET',
        headers: {
          'accept': 'application/vnd.oai.openapi+json',
          'Authorization': `Bearer ${tokenUpdated}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          // setFilesUploaded(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    useEffect(()=>{
      if(isSebiUser || isLaws){
        handleTab2();
      }
      fetch(`${API_URL}/docs/document/list_documents/`, {
        method: 'GET',
        headers: {
            'accept': 'application/vnd.oai.openapi+json',
            'Authorization': `Bearer ${tokenUpdated}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }}).then(function(response) {
          return response.json();
        })
        .then(function(data) {
          // setFilesUploaded(data)
        })
      .catch((err)=>{
        console.log(err);
      })
      fetchFilesUploaded();
    },[])

    // useEffect(()=>{
    //   const querySet = user?.querySet;
    //   let dataSet = querySet?.find((x)=> x?.dataset === 'sebi');
    //   if(dataSet){
    //     setIsSebiUser(true);
    //     handleTab2();
    //   }
    // },[user])

  const connect = (id, filesAndFolders, isFolder = false ) => {
    const host = new URL(API_URL)?.host;
    const file_url = `wss://${host}/ws/uploadFileStatus/${user?.id}/${id}/`;
    const folder_url = `wss://${host}/ws/uploadFolderStatus/${user?.id}/${id}/`;
    socket = new WebSocket(isFolder ? folder_url: file_url);

    socket.onopen = function(e) {
      console.log("Successfully connected to the WebSocket.");
    }

    socket.onclose = function(e) {
      console.log("WebSocket connection closed.");
      // setTimeout(function() {
      //   console.log("Reconnecting...");
      //   connect();
      // }, 2000);
    };

    socket.onmessage = function(e) {
      let uploadedFile;
      const data = JSON.parse(e.data);
      switch (data.type) {
        case 'connect_notification':
          console.log(data.message);
          break;
        case "fileupload_complete":
          uploadedFile = JSON.parse(data?.message)?.file;
          let temp = filesAndFolders?.map((folder,index)=>{
            if(folder.id === uploadedFile?.folder){
              let updatedDocuments = folder?.documents?.map(file => {
                if(file.id === uploadedFile.id) {
                  file.is_embedded = true;
                  file.embedding_status = 1;
                }
                return file;
              })
              folder.documents = updatedDocuments;
            }
            return folder;
          })
          setFilesUploaded(temp); 
          if(!isFolder){
            socket.close();
          }
          break;
        case "fileupload_fail":
          uploadedFile = JSON.parse(data?.message)?.file;
          alert(`Failed to upload ${uploadedFile?.name}!\nPlease retry after sometime.`);
          console.log(`Failed to upload ${uploadedFile?.name}!\nPlease retry after sometime.`);
          setUploadingDocumentIds(uploadingDocumentIds.filter(item => item !== uploadedFile?.id));
          let temp_for_file = filesAndFolders?.map((folder,index)=>{
            if(folder.id === uploadedFile?.folder){
              let updatedDocuments = folder?.documents?.filter(file => {
                return file.id !== uploadedFile.id;
              })
              folder.documents = updatedDocuments;
            }
            return folder;
          })
          setFilesUploaded(temp_for_file);
          if(!isFolder){
            socket.close();
          }
          break;
        case "folderprocessing_complete":
          socket.close();
          const uploadedFolder = JSON.parse(data?.message)?.folder;
          alert(uploadedFolder?.name +' uploaded successfully');
          break;
        default:
          console.log("Unknown message type!");
          break;
      }
    };

    socket.onerror = function(err) {
      console.log("WebSocket encountered an error: " + err.message,err);
      socket.close();
    }
  }

    function handleUpload(e){
      const data = new FormData();
      data.append('file',e.target.files[0] );
      setLoader(true);

      fetch(`${API_URL}/docs/document/`, {
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/vnd.oai.openapi+json',
            'Authorization': `Bearer ${tokenUpdated}`,
        }),
        credentials: 'include',
        mode: 'cors',
        body: data,
        Cache: 'default'
      }).then(function(response) {
        return response.json();
      })
      .then(function(data) {
        if(data?.error){
          throw data;
        }
        setLoader(false);
        connect(data?.id, filesUploaded);
        if(filesUploaded.length === 0){
          let temp_rootFolder = {
            id: data?.folder,
            name: user?.username,
            documents: [],
          }
          filesUploaded.push(temp_rootFolder);
        }
        if(!filesUploaded[0].documents){
          filesUploaded[0].documents = [data];
        }else{
          filesUploaded?.[0]?.documents?.unshift(data);
        }
        setFilesUploaded([...filesUploaded]);
        uploadingDocumentIds.push(data?.id);
        setUploadingDocumentIds([...uploadingDocumentIds]);
      })
      .catch((err)=>{
        setLoader(false);
        console.log(err);
        if(err?.error === "Document with the same name already exists for the profile."){
          alert("Document with the same name already exists for the profile.");
        }
      })
    }

    function handleUploadModalOpen(){
      setUploadModalOpen(!uploadModalOpen);
    }

    function handleDeleteFile(documentId){
      console.log(documentId,'documentId')
      fetch(`${API_URL}/docs/document/delete_documents/?document_id=${documentId}`, {
        method: 'GET',
        headers: {
            'accept': 'application/vnd.oai.openapi+json',
            'Authorization': `Bearer ${tokenUpdated}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }}).then(function(response) {
          return response.json();
        }).then((data) =>{
          toast('Document deleted Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            color: 'red'
          });
          fetchFilesUploaded();
        })
      .catch((err)=>{
        console.log(err);
      })
    }

    function handleDeleteFolder(folderId){
      console.log(folderId,'folderId')
      fetch(`${API_URL}/docs/folder/delete_folders/?folder_id=${folderId}`, {
        method: 'GET',
        headers: {
            'accept': 'application/vnd.oai.openapi+json',
            'Authorization': `Bearer ${tokenUpdated}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }}).then(function(response) {
          return response.json();
        }).then((data) =>{
          toast('Folder deleted Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            color: 'red'
          });
          fetchFilesUploaded();
        })
      .catch((err)=>{
        console.log(err);
      })
    }

    const handleLogoutUser = () => {
      fetch(`${API_URL}/users/logout/`, {
        method: 'POST',
        headers: {
            'accept': 'application/vnd.oai.openapi+json',
            'Authorization': `Bearer ${tokenUpdated}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }}).then(function(response) {
          console.log(response, 'response')
        })
        .then(function(data) {
          localStorage.clear();
          navigate(isSebiUser?"/sebi/login":isLaws?"/legalresearch/login":"/");
        })
      .catch((err)=>{
        console.log(err);
      })
    }

    const handleDeleteChat = (chatId) => {
      fetch(`${API_URL}/chatbot/conversations/${chatId}/`, {
        method: 'DELETE',
        headers: {
            'accept': 'application/vnd.oai.openapi+json',
            'Authorization': `Bearer ${tokenUpdated}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }}).then(function(response) {
          return response.json();
        }).then((data) =>{
          localStorage.removeItem('chatboxId');
          getChatHistory();
          toast('Chat deleted Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true, 
            draggable: true,
            progress: undefined,
            theme: "light",
            color: 'red'
          });
        })
      .catch((err)=>{
        console.log(err);
      })
    }

    const handleRenameChat = (chatId,chatName,lastQuery) => {
      setRenameChatid(chatId);
      setRename(chatName === 'New Chat' ? lastQuery ? lastQuery : '' : chatName);
    }

    const saveRenamechat = async () => {
      const form = new FormData();
      form.append('name', rename);
      setRenameChatid(null);
      await fetch(`${API_URL}/chatbot/conversations/${renameChatid}/rename/`, {
        method: 'PATCH',
        headers: {
            'accept': 'application/vnd.oai.openapi+json',
            'Authorization':`Bearer ${tokenUpdated}` ,
        },
        body: form
      })
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        console.log(data);
        getChatHistory();
      })
      .catch((err)=>{
        console.log(err);
      })
    }

    const handleBlur = () => {
      setRenameChatid(null);
    }

    const handleKeyDown = (e,chatName) => {
      if(e.key === "Enter"){
        if(rename.trim() === ''){
          alert('Chat name cannot be empty!');
          return;
        }
        if(rename === chatName){
          setRenameChatid(null);
        }
        else{
          saveRenamechat();
        }
      }
    }
    
    return (
      <>
        <div className="flex flex-col border-r border-borderGray w-[22%]" >
          <div className='pt-8 px-5'>
            {(isSebiUser || isLaws) && (
              <div className='w-11/12 m-auto'>
                <img src={DocChat_Black} alt='DocChat' width={'100%'} />
              </div>
            )}
            {(!isSebiUser && !isLaws) && (
              <div className='flex justify-around rounded-2xl'>
                <div onClick={handleTab1} className={activeTab === "documents" ? "tab" : "tabInactive"}>Documents</div>
                <div onClick={handleTab2} className={activeTab === "chat" ? "tab" : "tabInactive"}>History</div>
              </div>
            )}
            {activeTab === 'chat' ?
              <div className='newSession' style={{margin: '16px 0'}}>
                <div className='labelDocument' onClick={handleCreateNewChat}>{(isSebiUser||isLaws)?'+ New Query':' + New Chat'}</div>
              </div>
              :
              <div style={{margin:'16px 0'}}>
                <div className='newSession'>
                  <input type="file" name="uploadfile" id="img" className='hidden' onChange={(e) => handleUpload(e)}/>
                  <label htmlFor="img" className='labelDocument'> + New Document</label>
                </div>
                <div className='newSession' onClick={handleUploadModalOpen}>
                  <div className='labelDocument'> + New Folder</div>
                </div>
              </div>
            }
          </div>
          <ToastContainer/>
          {activeTab === 'chat' ?
            <div className='sebiHistoryWrapper'>
              { chatHistory.length > 0 ? chatHistory?.map((chat, index) => {
                const msgLenght = chat?.messages?.length;
                return(
                  <div 
                    key={index}
                    className='chatBox' 
                    style={selectedChat === chat?.id ? isSebiUser?{background:'black',color:'white'}:{background:'#4F3AFF',color: 'white'} : null}
                  >
                    {renameChatid === chat?.id ? (
                      <TextField
                        sx={{ input: { color: 'white', fontSize: '13px' } }}
                        variant='standard'
                        value={rename}
                        onChange={(e)=> setRename(e.target.value)}
                        onKeyDown={(e)=>handleKeyDown(e,chat?.name)}
                        onBlur={handleBlur}
                        autoFocus
                      />
                    ) : (
                      <div className='flex justify-between items-center w-full' onClick={() => handleOldHistoryChat(chat.id, index)}>
                        <div style={{wordBreak: 'break-word'}}>
                          <Tooltip title={chat?.name === 'New Chat' ? chat?.latest_query ? chat?.latest_query : 'No messages' : chat?.name}>
                            <p className='line-clamp text-left m-0' >
                              {
                                chat?.name === 'New Chat' 
                                ? chat?.latest_query ? chat?.latest_query : 'No messages' 
                                : chat?.name
                              }
                            </p>
                          </Tooltip>
                        </div>
                        <div className={selectedChat === chat?.id ? 'chatBox-icon' : 'chatBox-icon-hidden'}>
                          <Edit fontSize='small' onClick={() => handleRenameChat(chat?.id,chat?.name,chat?.latest_query)}/>
                          {(chatHistory?.length > 1) && (
                            <DeleteOutlinedIcon fontSize='small' onClick={() => handleDeleteChat(chat?.id)}/>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )
              }) : null}
            </div>
          : 
           <FilesUploadedList
              filesUploaded={filesUploaded}
              handleDeleteFile={handleDeleteFile}
              handleDeleteFolder={handleDeleteFolder}
              uploadingDocumentIds={uploadingDocumentIds}
           />
          }
          <div className='flex justify-center align-middle p-5 cursor-pointer' onClick={handleLogoutUser}><LogoutIcon className='mr-1' sx={{ color: '#4A3AFF' }}/> Log out</div>
        </div>
        {uploadModalOpen ? 
          <Modal
            open={uploadModalOpen}
            onClose={handleUploadModalOpen}
            closeAfterTransition
            disableEnforceFocus
            disableBackdropClick={loadingFolder}
          >
            <Box sx={style}>
              <FileUpload 
                setLoader={setLoader}
                setUploadModalOpen={setUploadModalOpen} 
                connect={connect}
                filesUploaded={filesUploaded}
                setFilesUploaded={setFilesUploaded}
                uploadingDocumentIds={uploadingDocumentIds}
                setUploadingDocumentIds={setUploadingDocumentIds}
              />
            </Box>
          </Modal>
          : 
          null
        }
      </>
    );
  }
  
  export default LeftSide;
  
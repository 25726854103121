import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from './apis/PrivateRoute';
import Login from './components/Login';
import Register from './components/Register';
import ChatPDf from './ChatPdf';
import ForgotPassword from './components/ForgotPasswordFlow/ForgotPassword';
import ForgotPasswordResult from './components/ForgotPasswordFlow/FogotPasswordResult';
import ResetPassword from './components/ForgotPasswordFlow/ResetPasssword';
import ResetPasswordResult from './components/ForgotPasswordFlow/ResetPasswordResult';
import Dashboard from './components/Admin/Dashboard';
import NotFound from './components/NotFound/NotFound';
import DraftingDashboard from './components/DraftingDashboard';
import { getDataSetClient } from './Utils/getDataSetClient';
import ContractDraftingLogin from './components/ContractDrafting/Login';
import { CONTRACT, } from './constants';
import GeneralRegister from './Shared/GeneralRegister';
import TemplateDraft from './components/ContractDrafting/TemplateDraft';
import ChatDraft from './components/ContractDrafting/ChatDraft';
import DisclaimerAndDisclosure from './components/DisclaimerAndDisclosuer';

export const isSebi =
  window.location.pathname?.split('/')?.[1] == 'sebi'
    ? true
    : false;
// export const isSebi =  true

export const isLaws = window.location.pathname?.split('/')?.[1] === 'legalresearch';
// export const isLaws = true;

const dataSetClient = getDataSetClient();


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {dataSetClient === CONTRACT && (
            <>
              <Route path="/contract/login" element={<ContractDraftingLogin />} />
              <Route path="/contract/register" element={<GeneralRegister />} />
              <Route path="/contract/chat/:draftId/:conversationId" element={<ChatDraft />} />
              <Route path='/contract/dashboard' element={<DraftingDashboard />} />
              <Route path='/contract/template/:id/:conversationId' element={<TemplateDraft />} />
            </>
          )}
          <Route exact path="register" element={<Register />} />
          <Route path="/sebi/register" element={<Register />} />

          <Route path="/" element={<Login />} />
          <Route path="/sebi/login" element={<Login />} />
          <Route exact path="chat" element={<PrivateRoute />} />
          <Route exact path="/sebi/chat" element={<PrivateRoute />} />
          <Route path="/legalresearch/register" element={<Register />} />
          <Route path="/legalresearch/login" element={<Login />} />
          <Route exact path="/legalresearch/chat" element={<PrivateRoute />} />

          <Route path='/disclaimer-and-disclosure' element={<DisclaimerAndDisclosure />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password-confirm" element={<ForgotPasswordResult />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password-confirm" element={<ResetPasswordResult />} />
          <Route path='/admin-dashboard' element={<Dashboard />} />
          <Route path='/not-found' element={<NotFound/>} />
          <Route path='/*' element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { Popper } from '@mui/base';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper } from '@mui/material';
import React from 'react';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import Grow from '@material-ui/core/Grow';
// import Paper from '@material-ui/core/Paper';
// import Popper from '@material-ui/core/Popper';
// import MenuItem from '@material-ui/core/MenuItem';
// import MenuList from '@material-ui/core/MenuList';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   paper: {
//     marginRight: theme.spacing(2),
//   },
// }));

export default function Laws({
	list,
	open,
	anchorRef,
	handleClose,
	handleSelect,
}) {
  // const classes = useStyles();

  return (
    <div >
      <div>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement='top-start' transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: 'center top' }}
            >
              <Paper style={{boxShadow: '1px 3px 8px #adadad', borderRadius: '8px'}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="menu-list-grow" >
										{list?.map((item, index)=>{
											return <MenuItem onClick={(e)=>handleSelect(e,item)} key={index}>{item.name}</MenuItem>
										})}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
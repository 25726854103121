import React from 'react';
import '../AuthForm.css';
import Graphics from '../Graphics';
import WebLogo from '../../images/WebLogo.png';
import { useNavigate } from "react-router-dom";

const ResetPasswordResult = () => {
  const navigate = useNavigate();
  let url = new URL(window.location.href);
  const dataset = (url.searchParams.get('dataset'));

  return (
    <div>
      <div className='auth-form-divider'>
        <div className='graphics'>
          <Graphics/>
        </div>
        <div className="auth-form-container">
          <div className='weblogoImg'>
            <img src={WebLogo} width={'180px'} height={'100%'}/>
          </div>
          <p className='message'>Password change completed successfully</p>
          {dataset === 'both' ? (
            <div>
              <button
                className='auth-button'
                onClick={() => {
                  navigate("/");
                }}
              >
                Login to DocChat
              </button>
              <button
                className='auth-button'
                onClick={() => {
                  navigate("/sebi/login");
                }}
              >
                Login to DocChat for Securities Market
              </button>
            </div>
          ):(
            <button
              className='auth-button'
              onClick={() => {
                navigate(dataset === 'sebi' ? '/sebi/login' : '/');
              }}
            >
              Click to Login
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordResult;
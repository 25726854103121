import { Add, Logout } from '@mui/icons-material';
import { FormControl, InputLabel, Select, MenuItem, Typography, Button, Table, TableHead, TableRow, TableCell, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Oval} from 'react-loader-spinner';
import { getDraftTypes, getFieldsByType, createContract, getContractsForUser } from '../../apis';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from '../../Utils/ChatToken';
import { API_URL } from '../../config/config';

const DOCUMENT_TITLES =[
    {
        "key": "name",
        "title": "Name",
    },
    {
        "key": "type_id",
        "title": "Type",
    },
    {
        "key": "status",
        "title": "Status",
    },
    // {
    //     "key": "created",
    //     "title": "Created",
    // },
];

function DraftingDashboard(){   
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [newDocumentName, setNewDocumentName] = useState('');
    const [draftTypes, setDraftTypes] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [selectedDocument, setSelectedDocument] = useState('');
    const [documentOptions, setDocumentOptions] = useState([]);
    const [fieldsByType, setFieldsByType] = useState([]);
    const [name, setName] = useState('');
    const [contracts, setContracts] = useState([]);
    const [fieldValues, setFieldValues] = useState({});
    const [loadingDasboard, setLoadingDashboard] = useState(false);
    const tokenUpdated = localStorage.getItem('chattoken');

    const navigate = useNavigate();

    useEffect(()=>{
        if( isLoggedIn() === '' ){ 
          navigate('/contract/login');
        }
      },[])

    const fetchContractsForUser = async () => {
        setLoadingDashboard(true);
        try {
            const data = await getContractsForUser(tokenUpdated);
            setContracts(data);
            setLoadingDashboard(false)
        } catch (error) {
            // Handle error if any
            setLoadingDashboard(false)
            console.error('Error fetching draft contracts:', error);
        }
    };

    useEffect(()=> {
        const fetchData = async () => {
            setLoadingDashboard(true);
            try {
                const data = await getDraftTypes(tokenUpdated);
                setDraftTypes(data);
                setLoadingDashboard(false);
            } catch (error) {
                // Handle error if any
                setLoadingDashboard(false);
                console.error('Error fetching draft types:', error);
            }
        };
            fetchContractsForUser();
            fetchData();
    },[])

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleCancel = () => {
        setNewDocumentName('');
        handleCloseDialog();
    };

    const handleSubmit = async () => {
        try {
            const contractData = {
                name: name,
                type_id: selectedDocument,
                userdata: fieldValues
            };

            const dataCreateContract = await createContract(contractData, tokenUpdated);

            toast('Contract created successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                color: 'red'
              });
            fetchContractsForUser();
            navigate(`/contract/template/${dataCreateContract?.contract?.id}/${dataCreateContract?.contract?.conversation}`)
        } catch (error) {
            console.error('Error creating contract:', error);
        }

        handleCloseDialog();
    };

    const handleTypeChange = (event) => {
        const selectedType = event.target.value;
        setSelectedType(selectedType);
        setSelectedDocument(''); // Reset selected document when type changes
        setDocumentOptions(draftTypes[selectedType]); // Set document options based on selected type
    };

    const handleDocumentChange = (event) => {
        setSelectedDocument(event.target.value);
        getFieldsData(event.target.value);
    };

    const getFieldsData = async (document) => {
        try {
            const data = await getFieldsByType(document, tokenUpdated);
            setFieldsByType(data);
        } catch (error) {
            console.error('Error fetching fields by type:', error);
        }
    };

    function handleAddNewDocument(){
        setIsDialogOpen(true);
    }

    function handleDocumentClick(item){
        navigate(`/contract/template/${item.id}/${item.conversation}`)
    }
     
    // Function to handle change in text field
    const handleDynamicValueChange = (e,field) => {
        const { value } = e.target;
        setFieldValues({ ...fieldValues, [field.name]: value });
    };

    const handleLogoutUser = () => {
        fetch(`${API_URL}/users/logout/`, {
          method: 'POST',
          headers: {
              'accept': 'application/vnd.oai.openapi+json',
              'Authorization': `Bearer ${tokenUpdated}`,
              'Content-Type': 'application/x-www-form-urlencoded'
          }}).then(function(response) {
            console.log(response, 'response')
          })
          .then(function(data) {
            localStorage.clear();
            navigate('/contract/login');
          })
        .catch((err)=>{
          console.log(err);
        })
      }

    return(
        <div className='m-20'>
           <div className='flex justify-between'>
                <Typography className='flex justify-between align-center m-1'>Documents</Typography>
                <div className='flex'>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Logout />}
                        onClick={handleLogoutUser}
                        style={{marginRight: '10px'}}
                    >
                    Logout
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Add />}
                        onClick={handleAddNewDocument}
                        
                    >
                        New Document
                    </Button>
                </div>
            </div>
            {loadingDasboard ? 
                <div className='flex justify-center align-center'>
                    <Oval
                        height="80"
                        width="80"
                        color="blue"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            :
            <Table className="">
                <TableHead className="">
                    <TableRow className=''>
                        {DOCUMENT_TITLES.map((header, index) => (
                            <TableCell className='' key={index}>{header.title}</TableCell>
                        ))}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                {(contracts?.length === 0 || contracts?.message === 'No contracts found.') ?
                    <TableRow style={{
                        textAlign: 'center',
                    }}>
                        <TableCell colSpan={6} style={{textAlign: 'center'}}>
                            <div style={{width: 'fit-content',margin:'3rem auto 4rem'}}>
                                <div className=''>Create your first Document</div>
                                <div className=''>Start with a document drafting you are working on</div>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    startIcon={<Add />}
                                    size="large"
                                    onClick={handleAddNewDocument}
                                >
                                    New Document
                                </Button>
                            </div>
                        </TableCell>
                    </TableRow>
                :
                (
                    contracts?.map((item, index) => (
                        <TableRow key={index} onClick={(e) =>handleDocumentClick(item)}>
                            {DOCUMENT_TITLES.map((title, idx) => (
                                <TableCell key={idx}>{item[title.key]}</TableCell>
                            ))}
                            <TableCell></TableCell>
                        </TableRow>
                    ))
                )}
            </Table>
            }
            <Dialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                sx={{ minWidth: '400px' }}
            >
                <DialogTitle id="dialog-title">Create New Document</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>Select Type</InputLabel>
                        <Select
                            value={selectedType}
                            onChange={handleTypeChange}
                        >
                            {Object.keys(draftTypes).map((type, index) => (
                                <MenuItem key={index} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: '1rem' }}>
                        <InputLabel>Select Document</InputLabel>
                        <Select
                            value={selectedDocument}
                            onChange={handleDocumentChange}
                        >
                            {documentOptions.map((doc, index) => (
                                <MenuItem key={index} value={Object.keys(doc)[0]}>{Object.values(doc)[0]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                         fullWidth
                         label='Name'
                         value={name}
                         onChange={(e) =>setName(e.target.value)}
                         style={{ marginTop: '1rem' }}
                    />
                    {fieldsByType?.map((field, index) => (
                        <TextField
                            key={index}
                            fullWidth
                            label={field.name}
                            onChange={(e) => handleDynamicValueChange(e, field)}
                            style={{ marginTop: '1rem' }}
                        />
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary" variant='outlined'>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" variant='outlined'>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer/>
        </div>
    )

}

export default DraftingDashboard;
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../AuthForm.css';
import { API_URL } from '../../config/config';
import Graphics from '../Graphics';
import WebLogo from '../../images/WebLogo.png'
import { Alert, InputAdornment, TextField } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

const Login = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [confirmpasswordType, setConfirmpasswordType] = useState('password');

  let url = new URL(window.location.href);
  const email = (url.searchParams.get('email'));
  const token = (url.searchParams.get('token'));
  const dataset = (url.searchParams.get('dataset'));
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{6,15}$/;

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  function validatePassword(password) {
    if (!passwordRegex.test(password)) {
      return false
    }
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(password !== confirmpassword){
      setError('Password must match');
      return;
    }
    if(!validatePassword(password)){
      setError("Must be minimum of 6 characters including atleast one upper case alphabet, lower case alphabet, number and special character.");
      return;
    }
    const data = {
      email,
      token,
      password: password,
    }
    fetch(`${API_URL}/api/reset_password/change/`, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      Cache: 'default'
    })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      navigate(`/reset-password-confirm?dataset=${dataset}`);
    })
    .catch((err)=>{
      console.log(err);
    })
  };

  const tooglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const toogleConfirmPasswordType = () => {
    if (confirmpasswordType === "password") {
      setConfirmpasswordType("text");
    } else {
      setConfirmpasswordType("password");
    }
  };

  return (
    <div className='auth-form-divider'>
      <div className='graphics'>
        <Graphics/>
      </div>
      <div className="auth-form-container">
      <div className='weblogoImg'>
          <img src={WebLogo} width={'180px'} height={'100%'}/>
        </div>
        <div style={{height:'52px'}}></div>
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className='title-input' htmlFor="password">Password</label>
            <TextField
              id='password'
              type={passwordType}
              placeholder='At least 6 characters'
              value={password}
              onChange={handlePasswordChange}
              required
              InputProps={{
                endAdornment: <InputAdornment position='end' onClick={tooglePasswordType} style={{ cursor: "pointer", color: '#5a5a5a' }}>
                  {passwordType === "password" ? (
                    <VisibilityOffOutlined fontSize='small' />
                  ) : (
                    <VisibilityOutlined fontSize='small' />
                  )}
                </InputAdornment>
              }}
            />
          </div>
          <div className="form-group">
            <label className='title-input' htmlFor="confirmpassword">Confirm Password</label>
            <TextField
              id='confirmpassword'
              type={confirmpasswordType}
              placeholder='At least 6 characters'
              value={confirmpassword}
              onChange={handleConfirmPasswordChange}
              required
              InputProps={{
                endAdornment: <InputAdornment position='end' onClick={toogleConfirmPasswordType} style={{ cursor: "pointer", color: '#5a5a5a' }}>
                  {confirmpasswordType === "password" ? (
                    <VisibilityOffOutlined fontSize='small'/>
                  ) : (
                    <VisibilityOutlined fontSize='small'/>
                  )}
                </InputAdornment>
              }}
            />
          </div>
          {error && (
            <Alert severity='error'>{error}</Alert>
          )}
          <button type="submit" className="auth-button">Reset password</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
import React, { useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function EditorTinymce({editorHtmlContent, setPlainText, setHtmlEditorContent, htmlEditorContent, plainText}) {
  const editorRef = useRef(null);

    function handleChangeEditor(){
      if (editorRef.current) {
        const htmlContent  = editorRef.current.getContent();
        const updatedHtmlContent = htmlContent.replace(/\\+/g, '').replace(/(?:\r\n|\r|\n)/g, '');
        setHtmlEditorContent(updatedHtmlContent);
        let plainText = htmlContent.replace(/<[^>]*>/g, "");
        setPlainText(plainText);
    }
    }
     
  return (
    <>
      <Editor
        apiKey='ti8488y3t0lvctbup5lbznayl2eklr4n0qvrfkfvso480t26'
        onInit={(evt, editor) => editorRef.current = editor}
        onEditorChange={handleChangeEditor}
        initialValue={editorHtmlContent}
        init={{
          height: '100%',
          width: '100%',
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          selector: 'textarea',
          setup: function (editor) {
            editor.on('init', function () {
                var content = "<p>Hello World </p>";
                editor.setContent(content);
            });
        }  
        }}
      />
    </>
  );
}
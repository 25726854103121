import React, {useEffect, useState, useRef} from "react";
import '../../App.css';
import LeftSide from "../../components/LeftSide";
import Chat from "../../components/Chat";
import { API_URL } from "../../config/config";
import {FallingLines} from 'react-loader-spinner';
import { isLoggedIn } from "../../Utils/ChatToken";
import { useNavigate, useParams } from "react-router-dom";
import EditorTinymce from "../../components/Editor/Tinymce";
import { getDataSetClient } from "../../Utils/getDataSetClient";
import { CONTRACT } from "../../constants";
import { createConversationDraft, communicateDraft, getFileContentsOfContract, publishDraft, getDraftConversationForId } from "../../apis";
import { ToastContainer, toast } from 'react-toastify';
import { Button } from "@mui/material";

function ChatDraft() {
  const [allChatData, setAllChatData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [loader, setLoader] = useState(false);
  const isInitialMount = useRef(true); // useRef to check initial mount
  const navigate = useNavigate();
  const dataSetClient = getDataSetClient();
  const {draftId, conversationId} = useParams();
  const [plainText, setPlainText] = useState('');
  const [htmlEditorContent, setHtmlEditorContent] = useState('');

  const tokenUpdated = localStorage.getItem('chattoken');
  const [chatBoxId, setChatBoxId] = useState(localStorage.getItem('chatboxId'));
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedChat, setSelectedChat] = useState(parseInt(chatBoxId));
  const [user,  setUser] = useState();

  const [filesUploaded, setFilesUploaded] = useState([])

const [editorHtmlContent, setEditorHtmlContent] = useState()

    const getDataFileContentsOfContract = async (draftId) => {
        try {
            const data = await getFileContentsOfContract(draftId, tokenUpdated);
            setEditorHtmlContent(data?.data);
        } catch (error) {
            console.error('Error fetching draft types:', error);
        }
    };

    const createNewDraftChat = async () => {
        try {
            const data = await createConversationDraft({"name":"NDA example conversation","contract_id":draftId}, tokenUpdated);
            console.log(data,'dataInchat')
            // setChatHistory([data, ...chatHistory]);
            // setSelectedChat(data?.id);
            localStorage.setItem('chatboxId', data.id);
            setChatBoxId(data.id);
        } catch (error) {
            console.error('Error creating conversation', error);
        }
    };

    useEffect(()=>{
      if( isLoggedIn() === '' ){ 
        navigate('/contract/login');
      }
      getUser();
      // getChatHistory();
    },[])

    useEffect(()=> {
        getDataFileContentsOfContract(draftId);
        handleOldHistoryChat(chatBoxId)
    },[draftId, chatBoxId])

    useEffect(()=>{
      // localStorage.setItem('chatboxId', conversationId)
      if(chatBoxId === "null" || !chatBoxId){
        createNewDraftChat();
      }
    },[chatBoxId])

  const handleCommandSubmit = async (command) => {
    if(command.trim() === ''){
      return;
    }
    setSpinner(true);
    const form = new FormData();
    form.append('content', command);

    const chatBoxId = localStorage.getItem('chatboxId');

    try {
        const data = await communicateDraft(chatBoxId,{"user_instructions":command,"draft_document":plainText}, tokenUpdated)
        setAllChatData([...allChatData, data]);
        console.log(allChatData,'allChatData')
        setSpinner(false);
    } catch (error) {
        console.error('Error fetching draft types:', error);
        setSpinner(false);
    }
  };

  // async function handleCreateNewChat(){
  //   const temp_user = user || JSON.parse(localStorage.getItem('user'));
  //   const form = new FormData();
  //   // const dataSet = isSebi ? 'sebi' : 'own';
  //   let querySetId;
  //   temp_user?.querySet?.forEach(element => {
  //     if(dataSetClient === element.dataset){
  //       querySetId = element.id;
  //     }
  //   });
  //   form.append('query_set', querySetId);
  //   await fetch(`${API_URL}/chatbot/conversations/`, {
  //     method: 'POST',
  //     body: form,
  //     headers: {
  //         'accept': 'application/vnd.oai.openapi+json',
  //         'Authorization': `Bearer ${tokenUpdated}`,
  //         // 'Content-Type': 'application/x-www-form-urlencoded'
  //     }}).then(function(response) {
  //       return response.json();
  //     })
  //       .then(function(data) {
  //         setChatHistory([data, ...chatHistory]);
  //         setSelectedChat(data?.id);
  //         localStorage.setItem('chatboxId', data.id);
  //         setChatBoxId(data.id);
  //       })
  //   .catch((err)=>{
  //     console.log(err);
  //   })
  //   // setAllChatData([]);
  // }

  // const setChatBox = async (chatHistoryList) => {
  //   // if (isInitialMount.current) {
  //   //   // Skip the initial mount
  //   //   isInitialMount.current = false;
  //   //   return;
  //   // }
  //   const latest_chatID = localStorage.getItem('chatboxId');
  //   setChatBoxId(latest_chatID);
  //   const isPriviousChatExist = parseInt(latest_chatID) || chatHistoryList?.[0]?.id;
  //   setSelectedChat(isPriviousChatExist);
  //   // if(isPriviousChatExist){
  //   //   handleOldHistoryChat(isPriviousChatExist);
  //   // }
  //   // else{
  //   //   handleCreateNewChat();
  //   // }
  // }

  // const getChatHistory = async () => {
  //   await fetch(`${API_URL}/chatbot/conversations/get_by_username/?dataset=${dataSetClient}`, {
  //     method: 'GET',
  //     headers: {
  //         'accept': 'application/vnd.oai.openapi+json',
  //         'Authorization': `Bearer ${tokenUpdated}`,
  //         'Content-Type': 'application/x-www-form-urlencoded'
  //     }}).then(function(response) {
  //       return response.json();
  //     })
  //     .then(function(data) {
  //       setChatHistory(data);
  //       // setChatBox(data);
  //     })
  //   .catch((err)=>{
  //     console.log(err);
  //   })
  // }
  
  const getUser = async () => {
    await fetch(`${API_URL}/users/user/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${tokenUpdated}`,
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((response)=>{
      return response.json();
    })
    .then((data)=>{
      setUser(data);
      // getChatHistory();
      localStorage.setItem('user', JSON.stringify(data));
    })
    .catch((err)=>{
      console.log(err);
    })
  }


  async function handleOldHistoryChat(chatId, index){
    // localStorage.setItem('chatboxId',chatId)
    setSelectedChat(chatId);
    setSpinner(true);
    setAllChatData([])

    try {
      const data = await getDraftConversationForId(chatBoxId, tokenUpdated);
      setAllChatData(data?.conversation_messages);
      setSpinner(false);
  } catch (error) {
      setSpinner(false);
      console.error('Error fetching draft types:', error);
  }
    // await fetch(`${API_URL}/chatbot/conversations/${chatId}/`, {
    //   method: 'GET',
    //   headers: {
    //       'accept': 'application/vnd.oai.openapi+json',
    //       'Authorization': `Bearer ${tokenUpdated}`,
    //       'Content-Type': 'application/x-www-form-urlencoded'
    //   }}).then(function(response) {
    //     return response.json();
    //   })
    //   .then(function(data) {
        // setAllChatData(data?.messages);
        // setSpinner(false);
    //   })
    // .catch((err)=>{
    //   console.log(err);
    //   setSpinner(false);
    // })
  }

  const handleLogoutUser = () => {
    fetch(`${API_URL}/users/logout/`, {
      method: 'POST',
      headers: {
          'accept': 'application/vnd.oai.openapi+json',
          'Authorization': `Bearer ${tokenUpdated}`,
          'Content-Type': 'application/x-www-form-urlencoded'
      }}).then(function(response) {
        console.log(response, 'response')
      })
      .then(function(data) {
        localStorage.clear();
        navigate('/contract/login');
      })
    .catch((err)=>{
      console.log(err);
    })
  }

  console.log('htmlEditorContnen', `${JSON.stringify(htmlEditorContent)}`)
  const handlePublishDocument = async (draftId) => {
      try {
        const data = await publishDraft(draftId, {data: `${JSON.stringify(htmlEditorContent)}`}, tokenUpdated);
        toast('Draft Contract updated successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          color: 'red'
        });
        navigate('/contract/dashboard')
      } catch (error) {
          console.error('Error fetching draft types:', error);
      }
  };
  console.log(allChatData,'allchatdata', plainText, htmlEditorContent)
  return (
    <div className="container">
     {loader ? 
        <div className='loaderWrapper'>
          <FallingLines
              color="#4A3AFF"
              width="100"
              visible={true}
              ariaLabel='falling-lines-loading'
            />
        </div>
        : null
      }
      <div className="flex flex-col m-2"> 
        <Button variant="contained" style={{marginBottom: '10px'}} onClick={()=>handlePublishDocument(draftId)}>Publish Document</Button>
        <Button variant="contained" style={{marginBottom: '10px'}} onClick={()=>navigate('/contract/dashboard')}>Dashboard</Button>
        <Button variant="contained" onClick={handleLogoutUser}>Logout</Button>
      </div>
      <Chat
        onCommandSubmit={handleCommandSubmit}
        allChatData={allChatData}
        spinner={spinner}
        user={user}
        filesUploaded={filesUploaded}
      />
      {dataSetClient === CONTRACT && 
        <EditorTinymce 
          editorHtmlContent={editorHtmlContent} 
          setPlainText={setPlainText} 
          setHtmlEditorContent={setHtmlEditorContent} 
          htmlEditorContent={htmlEditorContent}
          plainText={plainText}
        />
        }
        <ToastContainer/>
    </div>
  );
}

export default ChatDraft;

import React, {useEffect, useState, useRef} from "react";
import './App.css';
import LeftSide from "./components/LeftSide";
import MenuIcon from '@mui/icons-material/Menu';
import Chat from "./components/Chat";
import { API_URL } from "./config/config";
import {FallingLines} from 'react-loader-spinner';
import { isLoggedIn } from "./Utils/ChatToken";
import { useNavigate } from "react-router-dom";
import { isSebi } from "./App";
import EditorTinymce from "./components/Editor/Tinymce";
import { getDataSetClient } from "./Utils/getDataSetClient";
import { CONTRACT, GENERATE_JUDGEMENT } from "./constants";
import PdfViewer from "./components/PdfViewing/PdfViewer"; 
import { PdfViewingModal } from "./PdfViewerModal";

function ChatPDf() {
  // const [response, setResponse] = useState("");
  const [allChatData, setAllChatData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [loader, setLoader] = useState(false);
  const isInitialMount = useRef(true); // useRef to check initial mount
  const navigate = useNavigate();
  const dataSetClient = getDataSetClient();

  const tokenUpdated = localStorage.getItem('chattoken');
  const [chatBoxId, setChatBoxId] = useState(localStorage.getItem('chatboxId'));
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedChat, setSelectedChat] = useState(parseInt(chatBoxId));
  const [user,  setUser] = useState();
  const [pdfUrlForViewer, setPdfUrlForViewer] = useState('');
  const [sourcePageNumber, setSourcePageNumber] = useState(0);
  const [showPdfModal,setShowPdfModal]= useState(false);

  const [filesUploaded, setFilesUploaded] = useState([])

  useEffect(()=>{
    if( isLoggedIn() === '' ){ 
      navigate('/');
    }
    getUser();
    // getChatHistory();
  },[])

  const handleCommandSubmit = async (command) => {
    if(command.trim() === ''){
      return;
    }
    setSpinner(true);
    const form = new FormData();
    form.append('content', command);

    const chatBoxId = localStorage.getItem('chatboxId');

    fetch(`${API_URL}/chatbot/conversations/${chatBoxId}/communicate/`, {
        method: 'POST',
        headers: {
            'accept': 'application/vnd.oai.openapi+json',
            'Authorization':`Bearer ${tokenUpdated}` ,
        },
        body: form
    }).then(function(response) {
      return response.json();
    })
    .then(function(data) {
      console.log('response',data);
      if(command === GENERATE_JUDGEMENT){
        setTimeout(()=>{
          handleOldHistoryChat(chatBoxId)
        },120000)
        return;
      }
      setSpinner(false);
      setAllChatData([...allChatData, data]);
    })
    .catch((err)=>{
      console.log(err);
      setSpinner(false);
    })
  };

  async function handleCreateNewChat(){
    const temp_user = user || JSON.parse(localStorage.getItem('user'));
    const form = new FormData();
    // const dataSet = isSebi ? 'sebi' : 'own';
    let querySetId;
    temp_user?.querySet?.forEach(element => {
      if(dataSetClient === element.dataset){
        querySetId = element.id;
      }
    });
    form.append('query_set', querySetId);
    await fetch(`${API_URL}/chatbot/conversations/`, {
      method: 'POST',
      body: form,
      headers: {
          'accept': 'application/vnd.oai.openapi+json',
          'Authorization': `Bearer ${tokenUpdated}`,
          // 'Content-Type': 'application/x-www-form-urlencoded'
      }}).then(function(response) {
        return response.json();
      })
        .then(function(data) {
          setChatHistory([data, ...chatHistory]);
          setSelectedChat(data?.id);
          localStorage.setItem('chatboxId', data.id);
          setChatBoxId(data.id);
        })
    .catch((err)=>{
      console.log(err);
    })
    setAllChatData([]);
  }

  const setChatBox = async (chatHistoryList) => {
    // if (isInitialMount.current) {
    //   // Skip the initial mount
    //   isInitialMount.current = false;
    //   return;
    // }
    const latest_chatID = localStorage.getItem('chatboxId');
    setChatBoxId(latest_chatID);
    const isPriviousChatExist = parseInt(latest_chatID) || chatHistoryList?.[0]?.id;
    setSelectedChat(isPriviousChatExist);
    if(isPriviousChatExist){
      handleOldHistoryChat(isPriviousChatExist);
    }
    else{
      handleCreateNewChat();
    }
  }

  const getChatHistory = async () => {
    await fetch(`${API_URL}/chatbot/conversations/get_by_username/?dataset=${dataSetClient}`, {
      method: 'GET',
      headers: {
          'accept': 'application/vnd.oai.openapi+json',
          'Authorization': `Bearer ${tokenUpdated}`,
          'Content-Type': 'application/x-www-form-urlencoded'
      }}).then(function(response) {
        return response.json();
      })
      .then(function(data) {
        setChatHistory(data);
        setChatBox(data);
      })
    .catch((err)=>{
      console.log(err);
    })
  }
  
  const getUser = async () => {
    await fetch(`${API_URL}/users/user/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${tokenUpdated}`,
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((response)=>{
      return response.json();
    })
    .then((data)=>{
      setUser(data);
      getChatHistory();
      localStorage.setItem('user', JSON.stringify(data));
    })
    .catch((err)=>{
      console.log(err);
    })
  }


  async function handleOldHistoryChat(chatId, index){
    localStorage.setItem('chatboxId',chatId)
    setSelectedChat(chatId);
    setSpinner(true);
    setAllChatData([])
    await fetch(`${API_URL}/chatbot/conversations/${chatId}/`, {
      method: 'GET',
      headers: {
          'accept': 'application/vnd.oai.openapi+json',
          'Authorization': `Bearer ${tokenUpdated}`,
          'Content-Type': 'application/x-www-form-urlencoded'
      }}).then(function(response) {
        return response.json();
      })
      .then(function(data) {
        setAllChatData(data?.messages);
        setSpinner(false);
      })
    .catch((err)=>{
      console.log(err);
      setSpinner(false);
    })
  }

  function handleMenu(){
    setPdfUrlForViewer('')
    setShowPdfModal(false);
  }

  return (
    <div className="container max-w-full">
     {loader ? 
        <div className='loaderWrapper'>
          <FallingLines
              color="#4A3AFF"
              width="100"
              visible={true}
              ariaLabel='falling-lines-loading'
            />
        </div>
        : null
      }
      {pdfUrlForViewer !== '' && <MenuIcon className="cursor-pointer m-2" onClick={handleMenu}/>}
     {pdfUrlForViewer === '' &&
      <LeftSide
        handleCreateNewChat={handleCreateNewChat}
        handleOldHistoryChat={handleOldHistoryChat}
        setLoader={setLoader}
        chatHistory={chatHistory}
        selectedChat={selectedChat}
        user={user}
        getChatHistory={getChatHistory}
        filesUploaded={filesUploaded}
        setFilesUploaded={setFilesUploaded}
      />}
      <Chat
        onCommandSubmit={handleCommandSubmit}
        allChatData={allChatData}
        spinner={spinner}
        user={user}
        filesUploaded={filesUploaded}
        setPdfUrlForViewer={setPdfUrlForViewer}
        pdfUrlForViewer={pdfUrlForViewer}
        setSourcePageNumber={setSourcePageNumber}
        setShowPdfModal={setShowPdfModal}
      />
       {dataSetClient !== CONTRACT && pdfUrlForViewer !== '' && showPdfModal &&  <PdfViewingModal pdfUrl={pdfUrlForViewer} open={showPdfModal}/>}
      {dataSetClient === CONTRACT && <EditorTinymce/>}
    </div>
  );
}

export default ChatPDf;

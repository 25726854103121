import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './AuthForm.css';
import { API_URL } from '../config/config';
import { Link } from 'react-router-dom';
import MessageBox from '../Shared/MessageBox/MessageBox';
import Graphics from './Graphics';
import WebLogo from '../images/WebLogo.png';
import { isLaws, isSebi } from '../App';
import SebiGraphics from './SebiGraphic/SebiGraphic';
import { InputAdornment, TextField, Typography } from '@mui/material';
import EnvelopeSimple from '../images/EnvelopeSimple.svg';
import Lock from '../images/Lock.svg';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import Webnyay_favicon from '../images/Webnyay_favicon.svg';
import DocChat_Black from '../images/DocChat_Black.svg';
import {loginSubmission} from '../apis/index';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordType, setPasswordType] = useState('password');

  const navigate = useNavigate();

  useEffect(()=>{
    localStorage.setItem('isSebi',isSebi);
  },[])

  function handleRegisterClick() {
      navigate(isSebi?"/sebi/register":isLaws?"/legalresearch/register":"/register");
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
        "username_or_email": email,
        "password": password,
      }
    localStorage.setItem('chatUsername',email);
   
    try {
      const responseData = await loginSubmission(data);
  
      if (responseData?.token) {
        if ((isSebi && responseData?.dataset?.includes('sebi')) || (isLaws && responseData?.dataset?.includes('laws')) || (!isSebi && !isLaws && responseData?.dataset?.includes('own'))) {
          localStorage.setItem('chattoken', responseData.token.access);
          navigate(isSebi ? '/sebi/chat' : isLaws ? "/legalresearch/chat" : '/chat');
        } else {
          setError('Unauthorized user!');
        }
      } else if (responseData?.detail === 'Invalid credentials.') {
        setError(responseData?.detail);
      }
  
      return responseData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const errorStyles = {
    backgroundColor: 'rgb(253, 236, 234)',
    color: 'red',
    padding: '10px 15px',
    borderRadius: '4px',
    marginTop: '10px',
  }

  const tooglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };



  return (
    <div className='auth-form-divider'>
      <div className={isSebi?'sebi-graphic':'graphics'}>
        {isSebi ? <SebiGraphics/> : <Graphics/>}
      </div>
      <div className="auth-form-container">
        <div className='main-container'>  
          <div className='weblogoImg'>
            {isSebi ? (
              <img className='logo-circle' src={Webnyay_favicon} alt='webnyay'/>
            ) : (
              <>
                <img className='logo-circle' src={Webnyay_favicon} alt='webnyay'/>
                <div style={{height:'0.7rem'}}></div>
                <img src={DocChat_Black} width={'220px'} height={'100%'}/>
              </>
            )}
          </div>
          <div className='login-title'>Log in</div>
          <div className='login-subtitle'>Enter your account details below</div>
          <div style={{height: '52px'}}></div>
          <form className="auth-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email" className='title-input'>Email address</label>
              <TextField
                variant='outlined'
                type='email'
                id='email'
                placeholder='Eg - myemail@mail.com'
                value={email}
                onChange={handleEmailChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><img src={EnvelopeSimple} alt="Envelope" /></InputAdornment>
                }}
              />
            </div>
            <div style={{height:'10px'}}/>
            <div className="form-group">
              <label htmlFor="password" className='title-input'>Password</label>
              <TextField
                id='password'
                type={passwordType}
                placeholder='Enter password'
                value={password}
                onChange={handlePasswordChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><img src={Lock} alt="Lock" /></InputAdornment>,
                  endAdornment: <InputAdornment position='end' onClick={tooglePasswordType} style={{ cursor: "pointer", color: '#5a5a5a' }}>
                    {passwordType === "password" ? (
                      <VisibilityOffOutlined fontSize='small'/>
                    ) : (
                      <VisibilityOutlined fontSize='small'/>
                    )}
                  </InputAdornment>
                }}
              />
            </div>
            <div style={{display:'grid',justifyContent:"end",}}>
              <div>
                <Link to="/forgot-password" style={{textDecoration: 'none'}}>
                  <p className='forgot-button'>Forgot Password?</p>
                </Link>
              </div>
            </div>
            <button type="submit" className="auth-button" style={{borderRadius:0,fontSize:'1rem'}}>Login</button>
            {error && (
              <MessageBox 
                message={error}
                styles={errorStyles}
              />
            )}
          </form>
          <div className='auth-change'>
            Don't have an account? <span className="text-blue-600 font-medium underline hover:text-blue-700 cursor-pointer" onClick={handleRegisterClick}>Register</span>
          </div>
          {/* <div className='auth-change'>
            Don't have an account? <span className="auth-change-btn-change" onClick={handleRegisterClick}>Register</span>
          </div> */}
          <Typography><a href='/disclaimer-and-disclosure' className='hover:underline' target='_blank'>Disclaimer and Disclosure</a></Typography>
        </div>
      </div>
    </div>
  );
};

export default Login;
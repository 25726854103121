import React from 'react';
import { DialogTitle, Dialog, DialogContent, DialogActions, Button, Typography, Rating, TextField, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const ReviewFormDialog = ({
  open,
  handleClose,
  handleRatingChange,
  handleCommentChange,
  handleSubmitReview,
  ratings,
  comment,
  messageIdForReview,
}) => {
  return (
    <Dialog onClose={handleClose} open={open} sx={{'.MuiDialog-paper': {width: '25rem'}}}>
      <DialogTitle fontSize={'16px'} fontFamily={'Satoshi-Bold'}>
        Write a Review
        <IconButton style={{position:'absolute',right:8,top:8,color:'#c5c5c5'}} onClick={handleClose}><Close fontSize='small'/></IconButton>  
      </DialogTitle>
      <DialogContent dividers sx={{borderBottom: 'none'}}>
        <div className='ratingWrapper'>
          <div className='rating-legend'>
            <Typography component="legend">Accuracy</Typography>
         </div>
          <Rating
            name="accuracy-rating"
            value={ratings.accuracy_rating}
            onChange={(event, newValue) => handleRatingChange(newValue, 'accuracy_rating')}
            precision={1}
            style={{ width: '20px' }}
            sx={{'.MuiRating-icon':{paddingRight: '15px'}}}
          />
        </div>

        <div className='ratingWrapper'>
          <div className='rating-legend'>
            <Typography component="legend">Helpful</Typography>
         </div>
          <Rating
            name="helpful-rating"
            value={ratings.helpful_rating}
            onChange={(event, newValue) => handleRatingChange(newValue, 'helpful_rating')}
            precision={1}
            style={{ width: '20px' }}
            sx={{'.MuiRating-icon':{paddingRight: '15px'}}}
          />
        </div>

        <div className='ratingWrapper'>
          <div className='rating-legend'>
            <Typography component="legend">Source</Typography>
          </div>
          <Rating
            name="source-rating"
            value={ratings.source_rating}
            onChange={(event, newValue) => handleRatingChange(newValue, 'source_rating')}
            precision={1}
            style={{ width: '20px' }}
            sx={{'.MuiRating-icon':{paddingRight: '15px'}}}
          />
        </div>

        <div className='ratingWrapper'>
          <div className='rating-legend'>
            <Typography component="legend">Length</Typography>
          </div>
          <Rating
            name="length-rating"
            value={ratings.length_rating}
            onChange={(event, newValue) => handleRatingChange(newValue, 'length_rating')}
            precision={1}
            style={{ width: '20px' }}
            sx={{'.MuiRating-icon':{paddingRight: '15px'}}}
          />
        </div>

        <TextField
          label="Comment"
          multiline
          rows={4}
          variant="outlined"
          placeholder='Your feedback helps us to improve'
          fullWidth
          value={comment}
          onChange={handleCommentChange}
          style={{ marginTop: '10px' }}
        />
      </DialogContent>
      <DialogActions sx={{padding: '24px'}}>
        <Button variant='outlined' onClick={handleClose}>
          Cancel 
        </Button>
        <Button variant='contained' onClick={(event) => handleSubmitReview(event, messageIdForReview)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReviewFormDialog;

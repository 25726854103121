const scripts = [
    {
      name: 'pdfjsLib',
      src: 'https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.min.js',
    },
    {
      name: 'PDFLib',
      src: 'https://unpkg.com/pdf-lib@1.4.0/dist/pdf-lib.min.js',
    },
  ];
  
  const assets = {};
  export const getAsset = (scriptName) => assets[scriptName];
  
  export const prepareAssets = () => {
    // prepare scripts
    scripts.forEach(({ name, src }) => {
      assets[name] = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
          resolve(window[name]);
          console.log(`${name} is loaded.`);
        };
        script.onerror = () =>
          reject(`The script ${name} didn't load correctly.`);
        document.body.appendChild(script);
      });
    });
  };
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import '../AuthForm.css';
import {API_URL} from '../../config/config';
import Graphics from '../Graphics';
import WebLogo from '../../images/WebLogo.png'

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: email,
    }
    fetch(`${API_URL}/api/password_reset/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        Cache: 'default'
      }).then(function(response) {
        console.log('1',response)
        return response.json();
      })
      .then(function(data) {
        if(data?.status === 'OK'){
          navigate("/forgot-password-confirm");
        }
        if(data?.email){
          alert(data?.email);
        }
      })
      .catch((err)=>{
        console.log(err);
      })
  };

  return (
    <div className='auth-form-divider'>
      <div className='graphics'>
        <Graphics/>
      </div>
      <div className="auth-form-container">
        <div className='weblogoImg'>
            <img src={WebLogo} width={'180px'} height={'100%'}/>
          </div>
        <div style={{height:'52px'}}></div>
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email" className='title-input'>Email</label>
            <input className='input-box' type="email" id="email" placeholder='myemail@mail.com' value={email} onChange={handleEmailChange} required />
          </div>
          <button type="submit" className="auth-button">Password reset</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
import { Typography } from "@mui/material";
import React from "react";

export default function DisclaimerAndDisclosure(){
	const disclaimerPoints = [
		{
			heading: 'Generative AI Based Product',
			content: 'This product utilises Generative Artificial Intelligence (AI) technology, specifically powered by ChatGPT, an advanced language model developed by OpenAI.',
		},
		{
			heading: 'Use at Your Own Risk',
			content: 'Your use of this product is entirely at your own risk. While we strive to provide accurate and helpful information, we cannot guarantee the accuracy, completeness, or reliability of the responses generated.',
		},
		{
			heading: 'Not Legal Advice',
			content: 'This product is not intended to provide legal advice or consultation. Any information or responses generated should not be construed as such. For legal matters, please consult a qualified advocate or legal professional.',
		},
		{
			heading: 'Information Purposes Only',
			content: 'The content generated by this product is for informational purposes only. It should not be considered as a substitute for professional advice in any field.',
		},
		{
			heading: 'Verify Sources and Information',
			content: 'We strongly advise you to verify the sources and the accuracy of the information and responses generated by this product before relying on them for any decision-making purposes.',
		},
		{
			heading: 'Product Under Development',
			content: 'Please note that this product is currently being developed and improved. It is not be a complete or finished product, and features or functionalities may change without prior notice.',
		},
		{
			heading: 'No Responsibility or Liability',
			content: 'Webnyay (hereinafter referred to as "we," "our," or "us") does not claim any responsibility for the accuracy, reliability, or suitability of the output generated by this product. We shall not be liable in law for any direct, indirect, incidental, consequential, or punitive damages arising from the use of this product.',
		},
		{
			heading: 'Third-Party Content',
			content: 'This product may contain links to third-party websites or resources. Webnyay does not endorse, guarantee, or assume responsibility for the accuracy or reliability of any third-party content, products, or services mentioned or linked to from this product.',
		},
		{
			heading: 'No Warranties',
			content: 'To the fullest extent permitted by law, Webnyay disclaims all warranties, express or implied, regarding the performance, availability, or reliability of this product.',
		},
	]

  return (
		<div className="max-w-screen-lg mx-auto p-8">
			<p className="text-xl font-bold">Dochat by Webnyay Disclaimer and Disclosure</p>
			<div className="h-8" />
			<div className="flex flex-col gap-3">
				<Typography className="text-gray-600 text-left">
					Thank you for using Webnyay’s Generative AI product powered by ChatGPT. Before proceeding, please read the following disclaimer and disclosure carefully:
				</Typography>
				{disclaimerPoints.map((point,index) => {
					return (
						<div>
							<Typography className="text-gray-600 text-left">
								<span className="font-semibold text-black">{index+1}. {point.heading}:</span> {point.content}
							</Typography>
						</div>
					)
				})}
				<Typography className="text-gray-600 text-left">
					By using this product, you acknowledge and agree to the terms and conditions outlined in this disclaimer and disclosure. If you do not agree with any part of these terms, please refrain from using this product.
				</Typography>
				<Typography className="text-gray-600 text-left">
					This disclaimer and disclosure are subject to change without prior notice. It is your responsibility to review this document periodically for any updates or modifications. If you have any questions or concerns regarding this disclaimer, please contact us at <span className="text-blue-700">hello@webnyay.ai</span>.
				</Typography>
				<Typography className="text-gray-600 text-left">
					If any provision of this Disclaimer and Disclosure is found to conflict with any terms outlined in Webnyay.ai's Privacy Policy or Terms of Use, whether directly or indirectly, the terms of this Disclaimer and Disclosure shall take precedence and govern the use of this product, resolving any conflicts in favour of the provisions contained herein.
				</Typography>
			</div>
		</div>
	)
}
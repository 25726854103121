import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../config/config';
import './UploadFolder.css';
import { Button } from '@mui/material';

const FileUpload = ({setLoader, setUploadModalOpen, connect, filesUploaded, setFilesUploaded, uploadingDocumentIds, setUploadingDocumentIds}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [folderName, setFolderName] = useState('');
    const [folderId, setFolderId] = useState(null);
    const tokenUpdated = localStorage.getItem('chattoken');
    const [folder, setFolder] = useState({});

  const handleFolderChange = (e) => {
    const files = e.target.files;
    setSelectedFiles(files);
    setFolderName(files[0].webkitRelativePath.split('/')[0]);
  };

  const config = {
    headers: { Authorization: `Bearer ${tokenUpdated}` }
  };

  const handleCreateFolder = () => {
    axios
        .post(`${API_URL}/docs/folder/`, 
        new URLSearchParams({
        'name': folderName
        }) , 
        config)
      .then((response) => {
        setFolderId(response.data.id);
        setFolder(response.data); 
      })
      .catch((error) => {
        console.error('Error creating folder:', error);
      });
  };

const handleFileUpload = () => {
    if (!selectedFiles.length || !folderId) {
      console.error('Please select a folder and create it before uploading.');
      return;
    }
    setUploadModalOpen(false);
    setLoader(true);
    const uploadPromises = [];

    for (const file of selectedFiles) {
      const formData = new FormData();
      formData.append('folderId', folderId); // Pass the folderId for each file
      formData.append('file', file);

      const uploadPromise =  axios.post(`${API_URL}/docs/document/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${tokenUpdated}`,
          'accept': 'application/vnd.oai.openapi+json',
        },
        timeout: 600000,
      });
      uploadPromises.push(uploadPromise);
    };

    // Call API for each file in the folder
    Promise.all(uploadPromises)
      .then((responses) => {
        let documents = [];
        connect(folder?.id,filesUploaded,true);
        responses.forEach(response => {
          if(response.status === 201){
            let file_data = response.data;
            documents.push(file_data);
            // connect(file_data?.id,true);
            uploadingDocumentIds.push(file_data?.id);
            setUploadingDocumentIds([...uploadingDocumentIds]);
          }
        })
        folder.documents = documents;
        filesUploaded.push(folder);
        setFilesUploaded([...filesUploaded]);
        setLoader(false); // Hide loading screen
        // alert('upload Successful');
        // window.location.reload(false);
      })
      .catch((error) => {
        console.error('Error uploading files:', error);
        let error_response = error?.response;
        if(error_response?.status === 400){
          alert(error_response?.data?.error);
        }
        setLoader(false); // Hide loading screen on error
      });
  };

  return (
    <div>
      <h2>Upload Folder</h2>
      <div className='wrapperUpload'>
        <input
          type="text"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          placeholder="Enter folder name"
          className='inputbar'
        />
        <Button onClick={handleCreateFolder}>Create Folder</Button>
      </div>
      <div className='uploadBtnWrap'>
        <input type="file" onChange={handleFolderChange} webkitdirectory="" directory="" className='inputbar'/>
        <Button onClick={handleFileUpload} disabled={!folderId}>
          Upload Folder
        </Button>
      </div>
    </div>
  );
};

export default FileUpload;

